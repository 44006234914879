import get from 'lodash/get';

import { waitWhileCondition } from 'utils/requestWithTimeout';
import {
  getLenderApplicationRequest,
  approveLenderApplicationRequest,
  reApplyApplicationRequest,
  setHfdFirstPaymentDateRequest,
  resetLenderApplication,
  getLenderRedirectRequest,
  requestIncreasedAmountRequest,
  getCanChargeRequest,
  extendCareCreditSessionToken,
  ExtendCareCreditSessionProps,
  refreshLenderApplicationRequest,
} from 'store/apiRequests';
import {
  LENDER_APPLICATION_STATUSES,
  LENDERS_TYPE,
} from 'applicationConstants';
import moment from 'moment/moment';
import {
  SET_APPLICATION_DATA,
  SIGNED_AT,
  SELECT_OFFER,
  approveLenderApplicationAction,
  getLenderApplicationAction,
  SET_REQUESTED_AMOUNT,
  SET_EMPLOYMENT_DETAILS,
  SET_PAYMENT_DETAILS,
  SET_HFD_PAYMENT_DETAILS,
  SET_ANIMATION_FOR_LENDER,
  SET_GREAT_AMERICA_DETAILS,
  ENABLE_HARDPULL_DECLINE_NOTIFICATION,
  DISABLE_HARDPULL_DECLINE_NOTIFICATION,
  reApplyLenderApplicationAction,
  setPaymentDateForHfdAction,
  SET_ALPHAEON_EXTRA,
  getLenderRedirectAction,
  LENDER_APPLICATION_UPDATE,
  requestIncreasedAmountAction,
  resetLenderApplicationAction,
  getCanChargeAction,
  extendCareCreditSessionAction,
  moveApplicationToAnotherLocationAction,
  refreshLenderApplicationAction,
} from './actionTypes';
import { defThunkRequest } from '../../utils/defAsyncAction';
import {
  IApplicant, IApplication, ILenderApplication, ILenderApplicationApprove,
} from '../../types/application';
import { ILenderExtra, ISubmission } from '../../types/submissions';
import { IApplicationForm } from '../../types/appicationForm';
import { IResponse } from '../../types/otherData';
import { asyncDispatch } from '../../hooks/useAsyncDispatch';
import { createSubmissionByExistingAccount, searchForExistingAccount } from '../accountLookup';
import { assignCustomerThunk } from '../customers';

export function setConfettiAnimationPlayed() {
  return {
    type: SET_ANIMATION_FOR_LENDER,
  };
}

export function enableHardpullDeclineNotification() {
  return {
    type: ENABLE_HARDPULL_DECLINE_NOTIFICATION,
  };
}

export function lenderApplicationWSUpdate(data: IApplication) {
  return {
    type: LENDER_APPLICATION_UPDATE,
    payload: data,
  };
}

export function setAlphaeonExtra(payload: {
  photoIdType: string;
  photoIdState: string;
}) {
  return {
    type: SET_ALPHAEON_EXTRA,
    payload,
  };
}

export function disableHardpullDeclineNotification() {
  return {
    type: DISABLE_HARDPULL_DECLINE_NOTIFICATION,
  };
}

export function setApplicationData({ data }: { data: Partial<IApplicationForm> }) {
  return {
    type: SET_APPLICATION_DATA,
    payload: {
      data,
    },
  };
}

export function setEmploymentDetails(payload: {
  paycheck?: string;
  nextDate?: moment.Moment;
  prevDate?: moment.Moment;
}) {
  return {
    type: SET_EMPLOYMENT_DETAILS,
    payload,
  };
}

export function setPaymentDetails(payload: any) {
  return {
    type: SET_PAYMENT_DETAILS,
    payload,
  };
}

export function setHfdPaymentDetails(payload: any) {
  return {
    type: SET_HFD_PAYMENT_DETAILS,
    payload,
  };
}

export function setGreatamericaDetails(payload: any) {
  return {
    type: SET_GREAT_AMERICA_DETAILS,
    payload,
  };
}

export const setSignAt = (signedAt: string) => ({
  type: SIGNED_AT,
  payload: {
    signedAt,
  },
});

export const selectOffer = (payload: any) => ({
  type: SELECT_OFFER,
  payload,
});

export const setRequestedAmount = (payload: Partial<{
  loanAmount: number, creditLimit: number, minAmount: number
}>) => {
  return {
    type: SET_REQUESTED_AMOUNT,
    payload,
  };
};

const isPendingStatus = (lenderApplication: ILenderApplication) => {
  const localStatus = get(lenderApplication, 'application.status');
  const inRequest = get(lenderApplication, 'application.inRequest');
  return localStatus === LENDER_APPLICATION_STATUSES.callback
      || localStatus === LENDER_APPLICATION_STATUSES.locked
      || inRequest;
};

export const waitForOkStatus = ({
  response,
  lenderApplicationId,
  timeout = 110,
  refresh,
}: {response?:
    IResponse<ILenderApplication>, lenderApplicationId: string, timeout?: number, refresh?: boolean},
) => {
  if (
    response
    && !isPendingStatus(response.data)
  ) {
    return response;
  }

  return waitWhileCondition({
    request: () => getLenderApplicationRequest(lenderApplicationId, refresh),
    condition: (data: ILenderApplication) => {
      return isPendingStatus(data);
    },
    timeout,
    every: 2,
  });
};

export const approveApplication = defThunkRequest<ILenderApplicationApprove, ILenderApplication>({
  actionTypes: approveLenderApplicationAction,
  thunkSteps: [
    ({
      data: { values, applicationId, session },
      dispatch,
    }) => {
      dispatch(enableHardpullDeclineNotification());
      return approveLenderApplicationRequest({ applicationId, session, values });
    },
    ({
      prevResponse, data: { applicationId },
    }) => waitForOkStatus({
      response: prevResponse,
      lenderApplicationId: applicationId,
      timeout: 80,
    }),
  ],
});

export const getLenderApplication = defThunkRequest<{
  id: string;
  suppressWaiting?: boolean;
  refresh?: boolean;
}, ILenderApplication>({
  actionTypes: getLenderApplicationAction,
  thunkSteps: [
    ({
      data: { id, suppressWaiting, refresh },
    }) => (suppressWaiting
      ? getLenderApplicationRequest(id, refresh)
      : waitForOkStatus({
        lenderApplicationId: id,
        refresh,
        timeout: 60,
      })),
  ],
});

export const setPaymentDateForHfd = defThunkRequest<{id: string, firstPaymentDate: string}, ILenderApplication>({
  actionTypes: setPaymentDateForHfdAction,
  thunkSteps: [
    ({ data: { id, firstPaymentDate } }: any) => setHfdFirstPaymentDateRequest(id, { firstPaymentDate }),
  ],
});

export const reApplyLenderApplicationThunk = defThunkRequest<{
  id: string;
  body: {
    extra: ILenderExtra
  };
}, ILenderApplication>({
  actionTypes: reApplyLenderApplicationAction,
  thunkSteps: [
    ({
      data: { id, body },
    }) => reApplyApplicationRequest(id, body),
    ({ dispatch, data: { id }, history }) => {
      dispatch(getLenderApplication({ history, data: { id } }));
    },
  ],
});

type LenderRedirectResType = {ssoLink: string};
export const getLenderRedirectThunk = defThunkRequest<{
  lenderApplicationId: string;
  lender: LENDERS_TYPE
}, LenderRedirectResType>({
  actionTypes: getLenderRedirectAction,
  silentError: true,
  thunkSteps: [
    ({
      data: {
        lenderApplicationId,
        lender,
      },
    }) => getLenderRedirectRequest(lenderApplicationId, lender),
  ],
});

export const resetLenderApplicationThunk = defThunkRequest<{
  lenderApplicationId: string;
  extra: {
    status: string;
    stage: string
  }
}, ILenderApplication>({
  actionTypes: resetLenderApplicationAction,
  silentError: true,
  thunkSteps: [
    ({
      data: {
        lenderApplicationId, extra,
      },
    }) => resetLenderApplication(lenderApplicationId, extra),
  ],
});

export const requestIncreasedAmountThunk = defThunkRequest<{
  lenderApplicationId: string;
  amount: number;
}, {
  creditLimit: number;
  availableCredit: number;
  cliAmount: number
}>({
  actionTypes: requestIncreasedAmountAction,
  silentError: true,
  thunkSteps: [
    ({
      data: {
        lenderApplicationId, amount,
      },
    }) => requestIncreasedAmountRequest(lenderApplicationId, amount),
  ],
});

export const getCanChargeThunk = defThunkRequest<{
  applicationId: string;
}, boolean>({
  actionTypes: getCanChargeAction,
  silentError: true,
  thunkSteps: [
    ({
      data: {
        applicationId,
      },
    }) => getCanChargeRequest(applicationId),
  ],
});

export const extendCareCreditSessionThunk = defThunkRequest<ExtendCareCreditSessionProps, {session: string}>({
  actionTypes: extendCareCreditSessionAction,
  thunkSteps: [
    ({ data }) => extendCareCreditSessionToken(data),
  ],
});

type MoveLocationProps = {
  accountNumber: string;
  lender: LENDERS_TYPE;
  location: string;
  applicant: IApplicant;
  customer?: string;
};

export const moveApplicationToAnotherLocationThunk = defThunkRequest<MoveLocationProps, ISubmission>({
  actionTypes: moveApplicationToAnotherLocationAction,
  thunkSteps: [
    ({ data, dispatch, history }) => asyncDispatch(
      searchForExistingAccount,
      {
        accountNumber: data.accountNumber,
        lender: data.lender,
        location: data.location,
      },
      dispatch,
      history,
    ),
    ({
      prevResponse, data, dispatch, history,
    }) => {
      return asyncDispatch(
        createSubmissionByExistingAccount,
        {
          body: {
            lender_account: prevResponse.id,
            phone: data.applicant.cellPhone,
            email: data.applicant.email,
            zip_code: data.applicant.zipCode,
            dob: data.applicant.dob,
            legal_approval: {
              iAgree: true,
              terms: '',
              approvedAt: moment().toISOString(),
            },
          },
          location: data.location,
        },
        dispatch,
        history,
      );
    },
    async ({
      prevResponse, data, dispatch, history,
    }) => {
      if (data.customer) {
        await asyncDispatch(
          assignCustomerThunk,
          {
            submission: prevResponse.id,
            location: data.location,
            customer: data.customer,
          },
          dispatch,
          history,
        );
        return { data: prevResponse };
      }

      return { data: prevResponse };
    },
  ],
});

export const refreshLenderApplicationThunk = defThunkRequest<{applicationId: string}, ILenderApplication>({
  actionTypes: refreshLenderApplicationAction,
  thunkSteps: [
    ({ data }) => refreshLenderApplicationRequest(data.applicationId),
  ],
});
